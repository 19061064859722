var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown float-right mt-1 " },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            "no-caret": "",
            right: "",
            "toggle-class": "p-0 mr-1",
            variant: "link"
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function() {
                return [
                  _c("feather-icon", {
                    staticClass: "align-middle text-body",
                    attrs: { icon: "MoreVerticalIcon", size: "16" }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-n1 ml-2 " },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          value: "Approve",
                          expression: "'Approve'",
                          modifiers: { hover: true, top: true }
                        }
                      ],
                      staticClass: "btn-icon bg-white text-primary ml-2 mt-n5 ",
                      attrs: { size: "23", variant: "outline-white" }
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "CheckSquareIcon", size: "18" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          value: "Reject",
                          expression: "'Reject'",
                          modifiers: { hover: true, top: true }
                        }
                      ],
                      staticClass: "btn-icon bg-white text-danger ml-1 mt-n5",
                      attrs: { size: "23", variant: "outline-white" }
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "XSquareIcon",
                          size: "18",
                          variant: "outline-danger"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }